export const environment = {
  production: false,
  env: 'u.a.t',
  application: {
    clientId: 'c6697c36-f20a-43ef-82db-2de55f46f66c',
    tenantId: 'd145d1f9-d198-4bfc-963d-1ebccb89af5b',
    redirectUri: 'https://myworkflow-uat.chill.ie',
    postLogoutRedirectUri: '/',
    api: {
      endpoint: '',
      scopes: ['api://66ea810a-2823-42cf-8dcb-18ee23fb750d/Read'],
    },
  },
  onboardingCampaignId: 35,
  onboardingNoEmailCampaignId: 37,
  jira:{
    ticketEmail: "support@chilldev.atlassian.net"
  },
  eventStreamAPI: {
    address: 'https://workflowappstreamfunctions-uat.azurewebsites.net/',
    code: 'hbdkhplvi0FFNcUlVTqCwlqhhpjdwZP_Ghn60IHCPrrHAzFuLRZQ_g==',
  },
  apiManagement: {
    address: 'https://dev-api.chill.ie/mychill/uat/v1/',
    subscriptionKey: 'f97d1172955a4a42ad7e5de311ca11e8',
    webSocket: {
      address: 'https://url.chill.ie/uat',
      subscriptionKey: '1370ac63033543d7ae1040e0cbe8fb9f',
    },
  },
  graphAPI: {
    address:
      'https://graph.microsoft.com/v1.0/groups/2237d77b-b82e-4dee-9f1e-df5395f71449/members',
  },
  ingestionAPI:
    'https://ca-api-ingestion.delightfulhill-ce910ac8.northeurope.azurecontainerapps.io/',
  onboardingAPI:
    'https://ca-api-onbording.delightfulhill-ce910ac8.northeurope.azurecontainerapps.io/',
};
