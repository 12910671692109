import {
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

import { HeaderComponent } from 'src/app/modules/shared/components/header/header.component';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { RejectDocumentReasonComponent } from '../../components/reject-document-reason/reject-document-reason.component';
import { UploadToRelayAlertComponent } from '../../components/upload-to-relay-alert/upload-to-relay-alert.component';
import { IngestionService } from 'src/app/services/ingestion.service';
import { DiaryModel } from 'src/app/models/diary.Model';
import { AuthService } from 'src/app/services/auth.service';
import { AgentModel } from 'src/app/models/agent.Model';
import { EmailValidator } from '@angular/forms';
import { OnboardingDocumentStatusModel } from 'src/app/models/emailCampaigns/onboardingDocumentStatus.Model';
import { ReviewedDocumentDetailModel } from 'src/app/models/emailCampaigns/reviewedDocumentDetail.Model';
import { ChillPushCampaignModel } from 'src/app/models/chillPushCampaign.Model';
import { environment } from 'src/environments/environment';
import { CustomerDetails } from 'src/app/models/customerDetails.Model';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { KeyValueModel } from 'src/app/models/keyValue.model';
import { LandingDetailPageEvent } from 'src/app/models/events/landingDetailPageEvent.model';
import { EventService } from 'src/app/services/event.service';
import { ReviewDocumentEvent } from 'src/app/models/events/reviewDocumentEvent.model';
import { DownloadPackEvent } from 'src/app/models/events/downloadPackEvent.model';

@Component({
  selector: 'app-validate-diaries-page',
  templateUrl: './validate-diaries-page.component.html',
  styleUrls: ['./validate-diaries-page.component.sass'],
})
export class ValidateDiariesPageComponent implements OnInit {
  panelOpenState = 0;
  pendingDiaryList: DiaryModel[] = [];
  toDoDiaryList: DiaryModel[] = [];
  reviewedDiaryList: DiaryModel[] = [];
  previewRotate: number = 0;
  pdfRotateAngle: number = 0;
  todoMenuLoading: boolean = true;
  agent!: AgentModel;

  diaryInPreview!: DiaryModel;
  pageInPreview: number = 0;
  previewLoading: boolean = false;
  diaryIdInPreview = '';
  documentNameInPreview = '';
  fileInPreview: any;
  textListInPreview!: KeyValueModel[];
  pdfInPreview!: any;
  fileTypeInPreview: string = '';

  isDownloadEnabled = false;
  isDownloadInProgress = false;

  zoom: number = 1;
  rotation: number = 0;
  pageNumber: number = 1;
  totalPage: number = 0;
  contentType: string = '';
  flip: number = 1;
  rotate: number = 0;

  @ViewChild('messageTemplate') errorMessageTemplate!: TemplateRef<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      policyCode: string;
      agentEmailAssigned: string;
      dateAssigned: any;
    },
    private onboardingService: OnboardingService,
    private eventService: EventService,
    private ingestionService: IngestionService,
    private authService: AuthService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ValidateDiariesPageComponent>
  ) {}

  ngOnInit(): void {
    var user = this.authService.getUserDetail();

    this.agent = <AgentModel>{
      name: user?.name,
      email: user?.username,
    };

    // console.log(JSON.stringify(this.agent));

    this.ingestionService
      .getDiariesByPolicyCode(this.data.policyCode)
      .subscribe((res) => {
        this.toDoDiaryList = res.filter(
          (x: any) =>
            x.status.approved === false &&
            x.status.diaryOpen === true &&
            x.status.uploaded === true
        );

        this.todoMenuLoading = false;

        // console.log('TODO LIST => ');
        // console.warn(this.toDoDiaryList);

        this.pendingDiaryList = res.filter(
          (x: any) =>
            x.status.approved === false &&
            x.status.diaryOpen === true &&
            x.status.uploaded === false
        );

        // console.log('PENDING LIST => ');
        // console.warn(this.pendingDiaryList);

        this.reviewedDiaryList = res.filter(
          (x: any) => x.status.approved === true
        );
        debugger;
        let event = <LandingDetailPageEvent>{
          sessionId: sessionStorage.getItem('sessionId'),
          agent: <AgentModel>{
            name: user?.name,
            email: user?.username,
          },
          assignedEmail: this.data.agentEmailAssigned,
          assignedDate: this.data.dateAssigned,
          policyCode: this.data.policyCode,
          todoItems: <DiaryModel[]>this.toDoDiaryList,
          pendingItems: <DiaryModel[]>this.pendingDiaryList,
          approvedItems: this.reviewedDiaryList,
          datetime: new Date(),
        };

        this.eventService.landingDetailPageEvent(event);

        // console.log('Reviewed LIST => ');
        // console.warn(this.reviewedDiaryList);
      });
  }

  setStep(index: number) {
    this.panelOpenState = index;
  }

  resetPreviewVariables() {
    this.zoom = 1;
    this.rotation = 0;
    this.pageNumber = 1;
    this.totalPage = 0;
    this.contentType = '';
    this.flip = 1;
    this.rotate = 0;
  }

  getDocuments(diary: DiaryModel) {
    diary.agent = this.agent;

    this.resetPreviewVariables();
    this.previewLoading = true;
    this.textListInPreview = [];
    this.diaryIdInPreview = diary.id;
    this.documentNameInPreview = this.getDocumentName(diary.diaryTypeId);

    if (diary.docDetails[0].type == 'text') {
      this.textListInPreview = diary.docDetails[0].keyValues;

      if (
        diary.docDetails[0].value != '' &&
        diary.docDetails[0].value != null &&
        diary.docDetails[0].value != undefined
      ) {
        this.textListInPreview = this.textListInPreview || [];

        this.textListInPreview.push({
          key: 'item',
          value: diary.docDetails[0].value,
        });
      }
      this.pageInPreview = -1;
      this.fileTypeInPreview = 'text';

      this.diaryInPreview = diary;

      this.previewLoading = false;

      return;
    }

    if (diary.id !== this.diaryInPreview?.id) {
      this.resetRotate();
      this.pageInPreview = 0;
      this.fileInPreview = '';
    }

    this.onboardingService
      .getFile(diary.docDetails[this.pageInPreview])
      .subscribe(
        (res) => {
          if (res.type === 'application/pdf') {
            this.fileTypeInPreview = 'pdf';
            //this.previewImage = '../../../assets/images/Icon_pdf_file.svg';
            var tempBlob = new Blob([res], { type: res.type });
            var reader = new FileReader();
            reader.onload = () => {
              this.pdfInPreview = new Uint8Array(reader.result as ArrayBuffer);
            };
            reader.readAsArrayBuffer(tempBlob);
          } else {
            this.fileTypeInPreview = 'image';
            var reader = new FileReader();
            reader.readAsDataURL(res);
            reader.onload = (_event) => {
              this.fileInPreview = reader.result;
            };
          }

          this.diaryInPreview = diary;
          this.previewLoading = false;
        },
        (error) => {
          // console.log('Fail');
          this.fileInPreview = undefined;
          this.previewLoading = false;
        }
      );
  }

  getItemName(text: string) {
    return text.match(/[A-Z]+(?![a-z])|[A-Z]?[a-z]+|\d+/g)?.join(' ');
  }

  getDocumentName(diaryId: string) {
    let documentName = '';

    switch (diaryId) {
      case '2T':
        documentName = 'IBAN';
        break;
      case '2C':
        documentName = 'No Claims Bonus';
        break;
      case '2E':
        documentName = 'Main Licence';
        break;
      case '2F':
        documentName = 'Additional Driver';
        break;
      case '2D':
        documentName = 'Letter(s) of Driving Experience';
        break;
      case '2I':
        documentName = 'Gap In Cover Letter';
        break;
      case '2A':
        documentName = 'Proposal Form';
        break;
      case '2J':
        documentName = 'NCT Certificate';
        break;
      case '2L':
        documentName = 'Engineers Report';
        break;
      case '2N':
        documentName = 'Irish Registration';
        break;
      case '2H':
        documentName = 'Valuation of Specified Item(s)';
        break;
      case '2B':
        documentName = 'Direct Debit Mandate';
        break;
      case '2O':
        documentName = 'Proof of PPS';
        break;
      case '2P':
        documentName = 'Proof of Identification';
        break;
      case '9C':
        documentName = 'Commercial DDM';
        break;
      case '2S':
        documentName = 'Digital Journey';
        break;
      case '2U':
        documentName = 'Certificate Of Incorporation';
        break;
      case '2K':
        documentName = 'VLC';
        break;
      case '2Q':
        documentName = 'Proof Of Business Address';
        break;
      case '2V':
        documentName = 'Tax Registration Certificate';
        break;
      case '2R':
        documentName = 'Credit Worthiness Check';
        break;
      default:
        documentName = 'Additional Driver';
        break;
    }
    return documentName;
  }

  rejectDocument() {
    this.diaryInPreview.title = this.documentNameInPreview;

    const dialogRef = this.dialog.open(RejectDocumentReasonComponent, {
      // height: '400px',
      // position: { right: '0', top: '35px' },
      width: '500px',
      hasBackdrop: true,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.rejectReason?.length > 0) {
        this.diaryInPreview.reason = result.rejectReason;
        this.diaryInPreview.status.rejected = true;
        this.diaryInPreview.status.approved = false;
        this.diaryInPreview.status.uploaded = false;

        this.reviewDocumentEvent(this.diaryInPreview);
        // console.log(JSON.stringify(this.toDoDiaryList));
      }

      this.checkStatusDownloadButton();
    });
  }

  acceptDocument() {
    this.diaryInPreview.title = this.documentNameInPreview;

    if (this.diaryInPreview.title == 'IBAN') {
      this.diaryInPreview.reason =
        'Document has been accepted - You will receive an email in the next few days from Close Brothers Finance with a link to complete your finance agreement. This needs to be completed as soon as possible. If the link is expired or you have not received it, please let us know as soon as possible.';
    } else {
      this.diaryInPreview.reason = 'Document has been accepted';
    }

    this.diaryInPreview.status.approved = true;
    this.diaryInPreview.status.rejected = false;
    this.diaryInPreview.status.uploaded = true;

    this.reviewDocumentEvent(this.diaryInPreview);
    this.checkStatusDownloadButton();
  }

  reviewDocumentEvent(diary: DiaryModel) {
    var user = this.authService.getUserDetail();

    let event = <ReviewDocumentEvent>{
      sessionId: sessionStorage.getItem('sessionId'),
      diary: diary,
      assignedDate: this.data.dateAssigned,
      assignedEmail: this.data.agentEmailAssigned,
      eventTime: new Date(),
    };

    this.eventService.reviewDocumentEvent(event);
  }

  previousDocument() {
    this.resetRotate();
    this.pageInPreview--;
    this.getDocuments(this.diaryInPreview);
  }

  nextDocument() {
    this.resetRotate();
    this.pageInPreview++;
    this.getDocuments(this.diaryInPreview);
  }

  resetRotate() {
    this.previewRotate = 0;
    this.pdfRotateAngle = 0;
  }

  rotateToRight() {
    this.rotate += 90;
    this.previewRotate += 90;
    this.pdfRotateAngle += 90;
  }

  rotateToLeft() {
    this.rotate -= 90;
    this.previewRotate -= 90;
    this.pdfRotateAngle -= 90;
  }

  checkStatusDownloadButton() {
    let diaryWaitingReview = this.toDoDiaryList.filter((x) => !x.reason);

    // console.log(diaryWaitingReview?.length);

    if (diaryWaitingReview?.length == 0) this.isDownloadEnabled = true;
  }

  downloadNBPack() {
  
    this.isDownloadInProgress = true;

    let diaries: DiaryModel[] = [];

    // diaries.push(...this.toDoDiaryList);
    // diaries.push(
    //   ...this.pendingDiaryList.filter((x) => {
    //     x.title = this.getDocumentName(x.diaryTypeId);
    //     x.agent = this.agent;
    //   })
    // );
    // diaries.push(
    //   ...this.reviewedDiaryList.filter((x) => {
    //     x.title = this.getDocumentName(x.diaryTypeId);
    //     x.agent = this.agent;
    //   })
    // );

    this.pendingDiaryList.filter((x) => {
      x.title = this.getDocumentName(x.diaryTypeId);
      x.agent = this.agent;
      x.reason = 'Document is still outstanding';
      x.customerDetails = this.toDoDiaryList[0].customerDetails;
    });

    this.reviewedDiaryList.filter((x) => {
      x.title = this.getDocumentName(x.diaryTypeId);
      x.agent = this.agent;
      x.reason = 'Document has been accepted';
      x.customerDetails = this.toDoDiaryList[0].customerDetails;
    });

    diaries = this.toDoDiaryList.concat(
      this.pendingDiaryList,
      this.reviewedDiaryList
    );

    let event = <DownloadPackEvent>{
      sessionId: sessionStorage.getItem('sessionId'),
      diaries: diaries,
      eventTime: new Date(),
      assignedDate: this.data.dateAssigned,
      assignedEmail: this.data.agentEmailAssigned,
    };

    debugger;
    this.eventService.downloadPackEvent(event);
    
    this.ingestionService.saveDiaries(diaries).subscribe(
      (res) => {
        this.onboardingService.getNBPack(this.data.policyCode).subscribe(
          (res) => {
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(res);
            a.href = objectUrl;
            a.download = `MyChillWF_${this.data.policyCode}_NBPACK`;
            a.click();
            URL.revokeObjectURL(objectUrl);
            // this.loadingDownloadNBPack = false;

            this.triggerCampaign(diaries);
            this.openUpdateToRelayAlert();

            this.isDownloadInProgress = false;
          },
          (error) => {
            this.isDownloadInProgress = false;
            this.openErrorMessageDialog();
            // console.log('NBPack creation error ' + error);
          }
        );
      },
      (error) => {
        this.isDownloadInProgress = false;
        this.openErrorMessageDialog();
        // console.log(error);
      }
    );
  }

  openUpdateToRelayAlert() {
    // console.log(JSON.stringify(this.agent));
    const dialogRef = this.dialog.open(UploadToRelayAlertComponent, {
      // height: '400px',
      // position: { right: '0', top: '35px' },
      // width: '500px',
      hasBackdrop: true,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.isUploadedToRelay) {
        this.dialogRef.close({
          isUploadedToRelay: result?.isUploadedToRelay,
        });
      }
    });
  }

  openErrorMessageDialog() {
    const dialogRef = this.dialog.open(this.errorMessageTemplate, {
      // height: '400px',
      // position: { right: '0', top: '35px' },
      // width: '500px',
      hasBackdrop: true,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      // if (result?.isUploadedToRelay) {
      //   this.dialogRef.close({
      //     isUploadedToRelay: result?.isUploadedToRelay,
      //   });
      // }
    });
  }

  triggerCampaign(diaries: DiaryModel[]) {
    var documentsReviewDetail: ReviewedDocumentDetailModel[] = <
      ReviewedDocumentDetailModel[]
    >diaries.map((x) => {
      var data = { name: x.title, msg: x.reason };

      return data;
    });

    var isSupportEmail = diaries[0]?.customerDetails?.supportEmail
debugger
    let chillPushCampaign = <ChillPushCampaignModel>{}

    if(!isSupportEmail){
    let onboardingCampaignParameter = <OnboardingDocumentStatusModel>{
      ProposerFirstName: diaries[0]?.customerDetails?.name,
      EmailAddress: diaries[0]?.customerDetails?.email,
      PolicyCode: diaries[0]?.policyCode ,
      Docs: documentsReviewDetail,
    };

     chillPushCampaign 
     chillPushCampaign.chillCampaignId = environment.onboardingCampaignId,
     chillPushCampaign.parameters = onboardingCampaignParameter,
     chillPushCampaign.receivers= [diaries[0]?.customerDetails?.email]
    
  }else{
      chillPushCampaign.chillCampaignId =  environment.onboardingNoEmailCampaignId,
      chillPushCampaign.parameters = {diaries : diaries},
      chillPushCampaign.receivers = [environment.jira.ticketEmail]
  }


    this.ingestionService.sendCampaign(chillPushCampaign).subscribe(
      (res) => {
        // console.log(res);
        this.isDownloadInProgress = false;
      },
      (error) => {
        this.isDownloadInProgress = false;
        this.openErrorMessageDialog();
      }
    );
  }

  ///////////////////////////////
  zoomIn() {
    this.zoom += 0.5;
  }

  zoomOut() {
    this.zoom -= 0.5;
  }

  restoreZoom() {
    this.zoom = 1;
  }

  rotateLeft() {
    this.rotation -= 90;
  }

  rotateRight() {
    this.rotation += 90;
  }

  previousPage() {
    this.pageNumber -= 1;
  }

  nextPage() {
    this.pageNumber += 1;
  }

  mirror() {
    this.flip = this.flip * -1;
  }

  callback(pdf: PDFDocumentProxy) {
    // do anything with "pdf"

    this.totalPage = pdf.numPages;
  }
}
