import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import pkg from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, OnDestroy {
  activeUser: string | undefined = 'unknown user';
  username: string | undefined = '';
  isAuthenticated = false;

  private unsubscribe = new Subject<void>();

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {}
  ngOnDestroy(): void {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    console.warn(environment.env);
    console.warn('Version: ' + pkg.version);
    this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    if (sessionStorage.getItem('sessionId') == null) {
      sessionStorage.setItem('sessionId', crypto.randomUUID());
    }

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => {
        if (this.msalService.instance.getAllAccounts()[0] == undefined)
          this.login();
        this.setAuthenticationStatus();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (message: EventMessage) =>
            message.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this.unsubscribe)
      )
      .subscribe((message: EventMessage) => {
        const authResult = message.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(authResult.account);

        if (sessionStorage.getItem('sessionId') == null) {
          sessionStorage.setItem('sessionId', crypto.randomUUID());
        }
      });
    // this.login();
  }

  title = 'Workflow App';

  login(): void {
    this.msalService.instance.loginRedirect({
      scopes: ['User.Read','group.read.all','GroupMember.Read.All'],
    });
  }

  logout(): void {
    sessionStorage.removeItem('sessionId');
    this.msalService.instance.logoutRedirect();
  }

  setAuthenticationStatus(): void {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      activeAccount = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(activeAccount);
      
    }

    this.isAuthenticated = !activeAccount;
    this.activeUser = activeAccount?.username;
    this.username = activeAccount?.name;
  }
}
