import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { RoleGuardService } from './services/role-guard.service';
import { OnboardingPageComponent } from './modules/onboarding/pages/onboarding-page/onboarding-page.component';
import { AuthorizationResolver } from './resolvers/authorization.resolver';

const routes: Routes = [
  {
    path: '',
    component: OnboardingPageComponent,
    canActivate: [MsalGuard],
    resolve: {
      authorization: AuthorizationResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
