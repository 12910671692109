import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private msalService: MsalService, private http: HttpClient) {}

  getGroupUserDetail(): Observable<any> {
    const headers = new HttpHeaders().set('ConsistencyLevel', 'eventual');

    return this.http.get(`${environment.graphAPI.address}`);
  }

  getUserDetail() {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      activeAccount = this.msalService.instance.getAllAccounts()[0];
    }

    return activeAccount;
  }
}
