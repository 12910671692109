import { Component, EventEmitter, Input, Output } from '@angular/core';
import pkg from '../../../../../../package.json';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent {
  isUserLoggedIn: boolean = false;
  expandUserMenu: boolean = false;
  version = pkg.version;

  @Input() userEmail!: string;
  @Input() username!: string;

  @Output() onLogout = new EventEmitter();

  logout() {
    this.onLogout.emit();
  }

  expandMenu() {
    this.expandUserMenu = !this.expandUserMenu;
  }
}
