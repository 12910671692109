<div class="onboarding-page-container">
  <!-- <hr /> -->
  <div class="result-table">
    <div class="action-bar">
      <div class="total-container">
        <span class="total">Policy Count: {{ data.data.length }} </span>
        <span class="total" *ngIf="authorization.authorizedAdminAgent"
          >Selected Items: {{ selection.selected.length }}
        </span>
      </div>

      <div
        class="assign-options-container"
        *ngIf="authorization.authorizedAdminAgent"
      >
        <!-- <mat-slide-toggle
          color="primary"
          [(ngModel)]="isAssignModeOn"
          (toggleChange)="filterAssignedAgents()"
          labelPosition="before"
        >
          Assign Mode
        </mat-slide-toggle> -->
        <mat-slide-toggle
          color="primary"
          labelPosition="before"
          (toggleChange)="filterUnassignedOnly()"
        >
          Only Unassigned
        </mat-slide-toggle>
        <mat-form-field appearance="outline" id="agents-select-form-field">
          <mat-label>Agents</mat-label>
          <mat-select
            [formControl]="agentsSelectedControl"
            [(ngModel)]="agentsSelected"
          >
            <mat-option
              *ngFor="let agent of agents | orderBy : 'displayName' : 'asc'"
              [value]="agent"
              >{{ agent.displayName }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <button mat-raised-button color="primary" (click)="setAgentsSelected()">
          Assign
        </button>

      </div>
      <button mat-raised-button color="primary" (click)="openDialog()">
        No Match
      </button>
      <div>
        <form [formGroup]="onboardingForm" id="search-by-policy-code">
          <mat-form-field appearance="outline" class="no-hint">
            <mat-label>Search by policy code</mat-label>
            <input
              (focusout)="searchByPolicyCode()"
              matInput
              placeholder="Policy Code"
              formControlName="policyCode"
            />
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </form>
      </div>
    </div>

    <table mat-table [dataSource]="data" matSort>
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th
          mat-header-cell
          *matHeaderCellDef
          [style.display]="isAssignModeOn ? 'revert' : 'none'"
        >
          <mat-checkbox
            color="primary"
            (change)="toggleAllRows()"
            [checked]="isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          [style.display]="isAssignModeOn ? 'revert' : 'none'"
        >
          <mat-checkbox
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- PolicyCode Column -->
      <ng-container matColumnDef="policyCode">
        <th
          mat-sort-header
          mat-header-cell
          *matHeaderCellDef
          sortActionDescription="Sort by number"
        >
          Policy Code
        </th>
        <td mat-cell *matCellDef="let element">{{ element.policyCode }}</td>
      </ng-container>

      <!-- DateReceived Column -->
      <ng-container matColumnDef="dateSubmitted">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="dateUploaded">
          Date Received (oldest)
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.dateUploaded | date : "dd/MM/YYYY" }}
        </td>
      </ng-container>

      <!-- TimeReceived Column -->
      <ng-container matColumnDef="timeSubmitted">
        <th
          mat-header-cell
          *matHeaderCellDef
          sortActionDescription="Sort by dateUploaded"
          style="text-align: center"
        >
          Time Received
        </th>
        <td mat-cell *matCellDef="let row" style="text-align: center">
          {{ row.dateUploaded | date : "h:mm a" }}
        </td>
      </ng-container>

      <!-- diaryTotal Column -->
      <ng-container matColumnDef="diaryTotal">
        <th
          class="header-center"
          mat-sort-header="diaryTotal"
          mat-header-cell
          *matHeaderCellDef
          sortActionDescription="Sort by diaryTotal"
          style="text-align: center"
        >
          Total
        </th>
        <td mat-cell *matCellDef="let row" style="text-align: center">
          {{ row.diaryTotal }}
        </td>
      </ng-container>

      <!-- Number Column -->
      <ng-container matColumnDef="file">
        <th mat-header-cell *matHeaderCellDef>
          <mat-icon style="padding-left: 12px">file_present</mat-icon>
        </th>
        <td mat-cell *matCellDef="let row">
          <button
            mat-icon-button
            (click)="
              getPolicyDetails(row.policyCode, row.agentEmail, row.dateAssigned)
            "
          >
            <mat-icon
              aria-hidden="false"
              aria-label="Example home icon"
              fontIcon="file_present"
            ></mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Number Column -->
      <ng-container matColumnDef="agentAssigned">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="agentName"
          disableClear
        >
          Agent Assigned
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.agentName }}
        </td>
      </ng-container>
      <!-- Number Column -->
      <ng-container matColumnDef="dateAssigned">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="dateAssigned"
          disableClear
        >
          Date Assigned
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.dateAssigned | date : "dd/MM/YYYY - h:mm a" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <!-- <mat-paginator
      [length]="resultsLength"
      [pageSize]="30"
      aria-label="paginator"
    ></mat-paginator> -->

    <div
      class="no-records-found-container"
      *ngIf="!isLoadingResults && data.data.length == 0"
    >
      <span>No record found</span>
    </div>
    <div class="table-spinner-container" *ngIf="isLoadingResults">
      <mat-progress-spinner color="primary" mode="indeterminate">
      </mat-progress-spinner>
    </div>
  </div>
</div>
