import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reject-document-reason',
  templateUrl: './reject-document-reason.component.html',
  styleUrls: ['./reject-document-reason.component.sass'],
})
export class RejectDocumentReasonComponent {
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<RejectDocumentReasonComponent>
  ) {}
  isSaveButtonEnabled: boolean = false;
  maxCharacteresAllowed = 1000;
  typedCharacteres = 0;

  rejectDocumentForm = this.fb.group({
    notes: ['', Validators.required],
  });

  submit() {}

  notesTyped(event: any) {
    let value =
      this.rejectDocumentForm.controls?.notes.value?.trimStart().length ===
      undefined
        ? 0
        : this.rejectDocumentForm.controls?.notes.value?.trimStart().length;

    this.typedCharacteres = value;

    this.isSaveButtonEnabled = value > 5;
  }

  saveReason() {
    this.dialogRef.close({
      rejectReason: this.rejectDocumentForm.controls.notes.value,
    });
  }
}
