import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LandingMainPageEvent } from '../models/events/landingMainPageEvent.model';
import { LandingDetailPageEvent } from '../models/events/landingDetailPageEvent.model';
import { ReviewDocumentEvent } from '../models/events/reviewDocumentEvent.model';
import { DownloadPackEvent } from '../models/events/downloadPackEvent.model';
import { environment } from 'src/environments/environment';
import { agentAssignmentEvent } from '../models/events/agentAssignmentEvent.model';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(private _http: HttpClient) {}

  landingMaingPageEvent(model: LandingMainPageEvent) {
    const customHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set(
        'Ocp-Apim-Subscription-Key',
        environment.apiManagement.subscriptionKey
      );

    this._http
      .post(`${environment.apiManagement.address}agentlogin`, model, {
        headers: customHeaders,
      })
      .toPromise();
  }

  landingDetailPageEvent(model: LandingDetailPageEvent) {
    const customHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set(
        'Ocp-Apim-Subscription-Key',
        environment.apiManagement.subscriptionKey
      );

    this._http
      .post(`${environment.apiManagement.address}agentopen`, model, {
        headers: customHeaders,
      })
      .toPromise();
  }

  reviewDocumentEvent(model: ReviewDocumentEvent) {
    const customHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set(
        'Ocp-Apim-Subscription-Key',
        environment.apiManagement.subscriptionKey
      );

    this._http
      .post(`${environment.apiManagement.address}documentstatus`, model, {
        headers: customHeaders,
      })
      .toPromise();
  }

  downloadPackEvent(model: DownloadPackEvent) {
    const customHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set(
        'Ocp-Apim-Subscription-Key',
        environment.apiManagement.subscriptionKey
      );

    this._http
      .post(`${environment.apiManagement.address}agentdownload`, model, {
        headers: customHeaders,
      })
      .toPromise();
  }

  assignTaskEvent(model: agentAssignmentEvent) {
    const customHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set(
        'Ocp-Apim-Subscription-Key',
        environment.apiManagement.subscriptionKey
      );

    this._http
      .post(`${environment.apiManagement.address}agentassign`, model, {
        headers: customHeaders,
      })
      .toPromise();
  }
}
