import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-to-relay-alert',
  templateUrl: './upload-to-relay-alert.component.html',
  styleUrls: ['./upload-to-relay-alert.component.sass'],
})
export class UploadToRelayAlertComponent {
  constructor(public dialogRef: MatDialogRef<UploadToRelayAlertComponent>) {}

  confirmUploadToRelay() {
    this.dialogRef.close({
      isUploadedToRelay: true,
    });
  }
}
