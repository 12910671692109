<div class="app-main-container">
  <div class="header-container">
    <app-header
      (onLogout)="logout()"
      [userEmail]="activeUser!"
      [username]="username!"
    ></app-header>
  </div>
  <div class="content-container">
    <router-outlet ></router-outlet>
    <!-- <app-onboarding-page></app-onboarding-page> -->
  </div>
  <div class="footer-container"><app-footer></app-footer></div>
</div>
