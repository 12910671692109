import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OnboardingRoutingModule } from './onboarding-routing.module';
import { OnboardingPageComponent } from './pages/onboarding-page/onboarding-page.component';
import { MaterialModule } from '../material/material.module';
import { ValidateDiariesPageComponent } from './pages/validate-diaries-page/validate-diaries-page.component';
import { RejectDocumentReasonComponent } from './components/reject-document-reason/reject-document-reason.component';
import { SharedModule } from '../shared/shared.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { UploadToRelayAlertComponent } from './components/upload-to-relay-alert/upload-to-relay-alert.component';
import { PipesModule } from '../pipes/pipes.module';
import { SearchNoMatchUserComponent } from './components/search-no-match-user/search-no-match-user.component';

@NgModule({
  declarations: [
    OnboardingPageComponent,
    ValidateDiariesPageComponent,
    RejectDocumentReasonComponent,
    UploadToRelayAlertComponent,
    SearchNoMatchUserComponent,
  ],
  imports: [
    CommonModule,
    OnboardingRoutingModule,
    MaterialModule,
    SharedModule,
    PdfViewerModule,
    PipesModule,
  ],
  exports: [OnboardingPageComponent],
})
export class OnboardingModule {}
