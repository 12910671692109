import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './modules/shared/components/footer/footer.component';
import { HeaderComponent } from './modules/shared/components/header/header.component';
import { OnboardingModule } from './modules/onboarding/onboarding.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './modules/material/material.module';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

// function MsalInstanceFactory(): IPublicClientApplication {
//   return new PublicClientApplication({
//     auth: {

//       clientId: environment.application.clientId,
//       authority: `https://login.microsoftonline.com/${environment.application.tenantId}`,
//       redirectUri: '/',
//     },
//   });
// }
// export function MSALGuardConfigFactory(): MsalGuardConfiguration {
//   return {
//     interactionType: InteractionType.Redirect,
//     authRequest: {
//       scopes: ['user.read'],
//     },
//     loginFailedRoute: '/login-failed',
//   };
// }
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>([
    ['https://graph.microsoft.com/v1.0/me', ['user.read']],
    ['https://graph.microsoft.com/v1.0/groups', ['group.read.all']],
    [
      'https://management.azure.com/',
      ['https://management.azure.com/user_impersonation'],
    ],
  ]);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}
@NgModule({
  declarations: [AppComponent, FooterComponent, HeaderComponent],
  imports: [
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.application.clientId, // This is your client ID
          authority: `https://login.microsoftonline.com/${environment.application.tenantId}`, // This is your tenant ID
          redirectUri: environment.application.redirectUri, // This is your redirect URI
          postLogoutRedirectUri: environment.application.postLogoutRedirectUri,
          navigateToLoginRequestUrl: true,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false, // Set to true for Internet Explorer 11
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ['User.Read'],
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [
            environment.apiManagement.address,
            environment.application.api.scopes,
          ],
          ['http://graph.microsoft.com/v1.0/me', ['user.Read']],
          ['https://graph.microsoft.com/v1.0/me', ['user.read']],
          ['https://graph.microsoft.com/v1.0/groups', ['group.read.all']],
          [
            'https://management.azure.com/',
            ['https://management.azure.com/user_impersonation'],
          ],
        ]),
      }
    ),
    BrowserModule,
    AppRoutingModule,
    OnboardingModule,
    HttpClientModule,
    MaterialModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    // {
    //   provide: MSAL_INSTANCE,
    //   useFactory: MsalInstanceFactory,
    // },
    // {
    //   provide: MSAL_GUARD_CONFIG,
    //   useFactory: MSALGuardConfigFactory,
    // },
    // {
    //   provide: MSAL_INTERCEPTOR_CONFIG,
    //   useFactory: MSALInterceptorConfigFactory,
    // },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
