import { Component } from '@angular/core';

@Component({
  selector: 'app-overlapping-spinner',
  templateUrl: './overlapping-spinner.component.html',
  styleUrls: ['./overlapping-spinner.component.sass']
})
export class OverlappingSpinnerComponent {

}
