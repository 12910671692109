import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { MaterialModule } from '../material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { OverlappingSpinnerComponent } from './components/overlapping-spinner/overlapping-spinner.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';

@NgModule({
  declarations: [OverlappingSpinnerComponent, MessageDialogComponent],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
  ],
  exports: [ReactiveFormsModule, OverlappingSpinnerComponent],
})
export class SharedModule {}
