import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { UploadedDiariesModel } from '../models/uploadedDiaries.Model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  private DiaryUploaded$: Subject<UploadedDiariesModel[]> = new Subject<
    UploadedDiariesModel[]
  >();
  private hubConnection: signalR.HubConnection =
    new signalR.HubConnectionBuilder()
      // .withUrl(`${environment.apiManagement.address}diary-uploaded`, {
      .withUrl(
        `${environment.apiManagement.webSocket.address}?subscription-key=${environment.apiManagement.webSocket.subscriptionKey}`,
        {
          transport: signalR.HttpTransportType.WebSockets,
          skipNegotiation: true,
        }
      )
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

  constructor() {
    this.startConnection();
    this.ReceiveMsg();
    this.GetDiaryUploaded();
  }

  public async startConnection() {
    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch((err) =>
        setTimeout(() => {
          console.log('Error while starting connection: ' + err);
          this.startConnection();
        }, 5000)
      );
  }
  public async ReceiveMsg() {
    this.hubConnection?.on('ReceiveMsg', (data) => {
      // console.log(data);
    });
  }
  public async GetDiaryUploaded() {
    this.hubConnection?.on('GetDiaryUploaded', (data) => {
      this.DiaryUploaded$.next(data);
      // console.log( this.data$);
    });
  }

  public get AllFeedObservable(): Observable<UploadedDiariesModel[]> {
    return this.DiaryUploaded$.asObservable();
  }

  public async invokeGetDiaryUploaded() {
    this.hubConnection?.invoke('GetDiaryUploaded');
  }
}
