import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { DiaryDocDetail } from '../models/diaryDocDetails.Model';
import { UploadedDiariesModel } from '../models/uploadedDiaries.Model';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor(private _http: HttpClient) {}

  getCustomHeader(): HttpHeaders {
    const customHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set(
        'Ocp-Apim-Subscription-Key',
        environment.apiManagement.subscriptionKey
      );

    return customHeaders;
  }

  listUploadedDiaries(): Observable<UploadedDiariesModel[]> {
    return this._http.get<UploadedDiariesModel[]>(
      `${environment.apiManagement.address}api/Documents/GetUploadedDiaries`,
      { headers: this.getCustomHeader() }
    );
  }

  getDiaryByPolicyCode(policyCode: string): Observable<any> {
    return this._http.get(
      `${environment.apiManagement.address}api/Documents/GetUploadedDiaries`,
      { headers: this.getCustomHeader() }
    );
  }

  getFile(docDetail: DiaryDocDetail): Observable<Blob> {
    return this._http.post(
      `${environment.apiManagement.address}api/Documents/GetFile`,
      docDetail,
      { responseType: 'blob', headers: this.getCustomHeader() }
    );
  }

  getNBPack(policyCode: string): Observable<Blob> {
    return this._http.get(
      // `${environment.apiManagement.address}api/Documents/GetAllDocumentsPDF?subscription-key=${environment.apiManagement.subscriptionKey}&policyCode=${policyCode}`,
      `${environment.apiManagement.address}api/Documents/GetAllDocumentsPDF?policyCode=${policyCode}`,
      { responseType: 'blob', headers: this.getCustomHeader() }
    );
  }

  saveAssignedTask(tasks: UploadedDiariesModel[]): Observable<any> {
    return this._http.post(
      `${environment.apiManagement.address}api/Workflow/SaveUploadedDiariesAssigned`,
      tasks,
      { headers: this.getCustomHeader() }
    );
  }
}
