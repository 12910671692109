<div class="message-dialog-container">
  <h1 class="title">{{ title }}</h1>
  <div class="message">{{ message }}</div>
  <div class="actions">
    <button mat-flat-button color="primary" (click)="cancel()">
      {{ cancelButtonText }}
    </button>
    <button mat-flat-button color="primary" (click)="confirm()">
      {{ confirmButtonText }}
    </button>
  </div>
</div>
