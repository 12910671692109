import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.sass'],
})
export class MessageDialogComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  title: string = '';
  message: string = '';

  confirmButtonText: string = '';
  cancelButtonText: string = '';

  hideCancelButton: boolean = false;

  confirm() {}

  cancel() {}
}
