import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { DiaryModel } from '../models/diary.Model';
import { ChillPushCampaignModel } from '../models/chillPushCampaign.Model';
import { NoMatchUserModel } from '../models/noMatchUser.Model';

@Injectable({
  providedIn: 'root',
})
export class IngestionService {
  constructor(private _http: HttpClient) {}

  getCustomHeader(): HttpHeaders {
    const customHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set(
        'Ocp-Apim-Subscription-Key',
        environment.apiManagement.subscriptionKey
      );

    return customHeaders;
  }

  saveDiaries(diaries: DiaryModel[]): Observable<any> {
    return this._http.post(
      `${environment.apiManagement.address}api/Workflow/SaveCompleteDiaries`,
      diaries,
      { headers: this.getCustomHeader() }
    );
  }

  getDiariesByPolicyCode(policyCode: string): Observable<any> {
    return this._http.get(
      `${environment.apiManagement.address}api/Onbording/GetDiaryByPolicyCodeWF?PolicyCode=${policyCode}`,
      { headers: this.getCustomHeader() }
    );
  }

  getNoMatchUserByPhoneNumber(phoneNumber: string): Observable<NoMatchUserModel[]> {
    let phoneNumberObj: any = {PhoneNumber: phoneNumber};
    return this._http.post<NoMatchUserModel[]>(
      `${environment.apiManagement.address}api/User/GetNoMatchUserByPhone`,
      phoneNumberObj,
      { headers: this.getCustomHeader() }
    );
  }

  sendCampaign(chillCampaign: ChillPushCampaignModel): Observable<any> {
    return this._http.post(
      `${environment.apiManagement.address}api/document/sendCampaignWorkflow`,
      chillCampaign,
      { headers: this.getCustomHeader() }
    );
  }
}
