<div class="reject-document-reason-container">
  <form [formGroup]="rejectDocumentForm" (ngSubmit)="submit()">
    <div class="form-control">
      <label for="notes">Enter the reason for rejecting the document</label>
      <textarea
        (keyup)="notesTyped($event)"
        name="notes"
        id="notes"
        formControlName="notes"
        [maxlength]="1000"
      ></textarea>
      <div class="typed-characters-container" style="text-align: end">
        {{ typedCharacteres }} / {{ maxCharacteresAllowed }}
      </div>
    </div>

    <div class="actions">
      <button mat-dialog-close type="submit" id="btn-cancel">
        <mat-icon> cancel</mat-icon> <span>Cancel</span>
      </button>
      <button
        type="submit"
        id="btn-save"
        [disabled]="!isSaveButtonEnabled"
        (click)="saveReason()"
      >
        <mat-icon> save</mat-icon> <span>Save</span>
      </button>
    </div>
  </form>
</div>
