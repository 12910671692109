import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { countryPhoneCode } from '../models/countryPhoneCodes.model';

@Injectable({
  providedIn: 'root',
})
export class DiaryService {
  constructor(private _http: HttpClient) {}


  countryCodes(): Observable<countryPhoneCode[]> {
    const apiUrl: string = '../assets/CountryCodes.json' ;

    return this._http.get<countryPhoneCode[]>(apiUrl);
  }
}
