import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { NoMatchUserModel } from 'src/app/models/noMatchUser.Model';
import { FormBuilder, FormControl } from '@angular/forms';
import { IngestionService } from 'src/app/services/ingestion.service';
import { delay } from 'lodash';
import { DiaryService } from 'src/app/services/diary.service';
import { countryPhoneCode } from 'src/app/models/countryPhoneCodes.model';

@Component({
  selector: 'app-search-no-match-user',
  templateUrl: './search-no-match-user.component.html',
  styleUrls: ['./search-no-match-user.component.sass'],
})
export class SearchNoMatchUserComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  isLoadingResults = false;
  displayedColumns: string[] = ['phoneNumber', 'email', 'dob', 'timestamp'];
  data = new MatTableDataSource<NoMatchUserModel>();
  countryCodesSelected: string = '';
  countryCodesControl = new FormControl('');
  countryCodes: countryPhoneCode[] = [];
  noMatchForm = this.fb.group({
    phoneNumber: [''],
  });

  constructor(
    private diaryService: DiaryService,
    private fb: FormBuilder,
    private ingestionService: IngestionService
  ) {}

  ngOnInit(): void {
    this.data.sort = this.sort;
    this.diaryService.countryCodes().subscribe({
      next: (x) => {
        this.countryCodes = x;
        this.countryCodesSelected = 'IE';
      },
      error: (e) => {
        this.isLoadingResults = false;
        console.error(e);
      },
    });
  }

  searchByPhoneNumber() {
    let phoneNumber = this.noMatchForm.controls?.phoneNumber.value ?? '';
    
    if(phoneNumber == '') return

    this.isLoadingResults = true;
    this.data.data = [];

    let countryCode = this.countryCodes.find(
      (x) => x.code == this.countryCodesSelected
    )?.dial_code;

    if (countryCode == '+353') phoneNumber = phoneNumber.replace(/^0+/, '');

    console.log(countryCode + phoneNumber);
    this.ingestionService
      .getNoMatchUserByPhoneNumber(countryCode + phoneNumber)
      .subscribe({
        next: (x) => {
          this.data.data = x;
          this.isLoadingResults = false;
          console.log(x);
        },
        error: (e) => {
          this.isLoadingResults = false;
          console.error(e);
        },
      });
  }
}
