<div class="header-container">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <span>Workflow App</span>
      <span class="example-spacer"></span>

      <div class="username-container">
        <div *ngIf="username">
          Hi,
          <a class="username">
            {{ username }}
          </a>
        </div>
        <button
          mat-icon-button
          (click)="expandMenu()"
          class="example-icon"
          aria-label="Example icon-button with menu icon"
        >
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <div
        class="user-menu mat-elevation-z4"
        [style.display]="expandUserMenu ? 'flex' : 'none'"
      >
        <button mat-menu-item>
          <mat-icon aria-hidden="false" aria-label="Example home icon">
            account_circle
          </mat-icon>
          <span>{{ userEmail }}</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item>
          <mat-icon>tag</mat-icon>
          <span>v{{ version }}</span>
        </button>
      </div>
      <!-- </div> -->
    </mat-toolbar-row>
  </mat-toolbar>
</div>
