<div class="page-container">

    <div class="action-bar">
        <form [formGroup]="noMatchForm" id="search-by-policy-code">
            <div class="form-fields">
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Country Code</mat-label>
                    <mat-select [formControl]="countryCodesControl" [(value)]="countryCodesSelected">
                        <mat-option *ngFor="let countryCode of countryCodes | orderBy : 'name' : 'asc'"
                            [value]="countryCode.code">{{ countryCode.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Search by phone</mat-label>
                    <input (blur)="searchByPhoneNumber()" matInput placeholder="851234567"
                        formControlName="phoneNumber" />
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div>
        <table mat-table [dataSource]="data" matSort>
            <ng-container matColumnDef="phoneNumber">
                <th mat-header-cell *matHeaderCellDef>
                    Phone Number
                </th>
                <td mat-cell *matCellDef="let element">{{ element.phoneNumber }}</td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>
                    Email
                </th>
                <td mat-cell *matCellDef="let element">{{ element.email }}</td>
            </ng-container>
            <ng-container matColumnDef="dob">
                <th mat-header-cell *matHeaderCellDef>
                    DOB
                </th>
                <td mat-cell *matCellDef="let element">{{ element.dob }}</td>
            </ng-container>
            <ng-container matColumnDef="timestamp">
                <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by number">
                    Date
                </th>
                <td mat-cell *matCellDef="let element"> {{element.timestamp | date: 'dd/MM/yyyy H:mm aaa'}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="no-records-found-container" *ngIf="!isLoadingResults && data.data.length == 0">
            <span>No record found</span>
            <div>Please, Make sure the country is selected correctly.</div>

        </div>
        <div class="table-spinner-container" *ngIf="isLoadingResults">
            <mat-progress-spinner color="primary" mode="indeterminate">
            </mat-progress-spinner>
        </div>
    </div>
</div>