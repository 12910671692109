import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Account } from '../models/accountAAD.model';
import { Authorization } from '../models/authorization.model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleGuardService {
  constructor(private authService: MsalService) {}

  Authorization(): Observable<Authorization> {
    let Authorizated: Authorization = new Authorization();
    let account: Account = this.authService.instance.getAllAccounts()[0];
    if (!account.idTokenClaims?.roles) return of(Authorizated);

    Authorizated.authorizedAdminAgent = account.idTokenClaims?.roles?.some(
      (x: string) => x === 'AdminAgent'
    );
    Authorizated.authorizedAgent = account.idTokenClaims?.roles?.some(
      (x: string) => x === 'Agent'
    );
    Authorizated.authorizedAdminIT = account.idTokenClaims?.roles?.some(
      (x: string) => x === 'AdminIT'
    );

    // there is no AdminIT functionality yet
    Authorizated.authorizedAdminAgent =
      Authorizated.authorizedAdminIT === true
        ? true
        : Authorizated.authorizedAdminAgent;

    return of(Authorizated);
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    //const expectedRole = route.data['roles'][0];

    let account: Account = this.authService.instance.getAllAccounts()[0];

    if (!account.idTokenClaims?.roles) {
      window.alert(
        'Token does not have roles claim. Please ensure that your account is assigned to an app role and then sign-out and sign-in again.'
      );
      return false;
    }
    // else if (!account.idTokenClaims?.roles?.includes(expectedRole)) {
    //   window.alert('You do not have access as expected role is missing. Please ensure that your account is assigned to an app role and then sign-out and sign-in again.');
    //   return false;
    // }
    // console.log('idTokenClaims ' + account.idTokenClaims?.roles);
    return true;
  }
}
