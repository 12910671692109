<div class="validate-diaries-page-container">
  <!-- <mat-toolbar color="primary">
    <button
      mat-icon-button
      class="example-icon"
      aria-label="Example icon-button with menu icon"
    >
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span>Policy xxxxxx</span>
  </mat-toolbar> -->

  <mat-card>
    <mat-card-header>
      <mat-card-title
        ><h1>Policy Code: {{ data.policyCode }}</h1></mat-card-title
      >
      <button mat-icon-button mat-dialog-close>
        <mat-icon
          aria-hidden="false"
          aria-label="Example home icon"
          fontIcon="close"
        ></mat-icon>
      </button>
    </mat-card-header>
    <mat-card-content>
      <mat-drawer-container class="example-container">
        <mat-drawer class="diary-menu" mode="side" opened>
          <!-- <mat-list>
            <div mat-subheader>To do</div>
            <mat-list-item>
              <mat-icon matListItemIcon>folder</mat-icon>
              <div matListItemTitle>Ducument type</div>
              <div matListItemLine>Document name</div>
            </mat-list-item>
            <mat-divider></mat-divider>
            <div mat-subheader>Pending</div>
            <mat-list-item>
              <mat-icon matListItemIcon>note</mat-icon>
              <div matListItemTitle>Ducument type</div>
              <div matListItemLine>Document name</div>
            </mat-list-item>
            <mat-divider></mat-divider>
            <div mat-subheader>Reviewed</div>
            <mat-list-item>
              <div matListItemTitle>Ducument type</div>
              <div matListItemLine>Document name</div>
              <mat-icon matListItemIcon>note</mat-icon>
            </mat-list-item>
          </mat-list> -->

          <mat-accordion>
            <!-- <mat-expansion-panel hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title> This is the expansion title </mat-panel-title>
                <mat-panel-description>
                  This is a summary of the content
                </mat-panel-description>
              </mat-expansion-panel-header>
              <p>This is the primary content of the panel.</p>
            </mat-expansion-panel> -->

            <div>
              <mat-expansion-panel
                [expanded]="panelOpenState === 0"
                (opened)="setStep(0)"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon matListItemIcon>list_alt_outline</mat-icon>
                    <span>To do</span>
                  </mat-panel-title>
                  <!-- <mat-panel-description>
                  Currently I am {{ panelOpenState ? "open" : "closed" }}
                </mat-panel-description> -->
                </mat-expansion-panel-header>
                <mat-list>
                  <div class="todo-menu-spinner" *ngIf="todoMenuLoading">
                    <mat-spinner></mat-spinner>
                  </div>

                  <mat-list-item
                    *ngFor="let diary of toDoDiaryList"
                    (click)="getDocuments(diary)"
                  >
                    <div class="diary-information" *ngIf="!todoMenuLoading">
                      <div>
                        <div matListItemTitle>
                          {{ getDocumentName(diary.id) }}
                        </div>
                        <div matListItemLine>
                          {{ diary.id }}
                        </div>
                      </div>

                      <div class="reviewed-label">
                        <span
                          class="green"
                          *ngIf="diary.status.approved"
                          [matTooltip]="diary.reason"
                          matTooltipPosition="right"
                          matTooltipHideDelay="500"
                          >Accepted</span
                        >
                        <span
                          class="red"
                          *ngIf="diary.status.rejected"
                          [matTooltip]="diary.reason"
                          matTooltipPosition="right"
                          matTooltipHideDelay="500"
                          >Rejected</span
                        >
                      </div>
                    </div>

                    <mat-divider></mat-divider>
                  </mat-list-item>
                </mat-list>
              </mat-expansion-panel>
              <mat-expansion-panel
                [expanded]="panelOpenState === 1"
                (opened)="setStep(1)"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon matListItemIcon>hourglass_empty_outline</mat-icon>
                    <span>Pending</span>
                  </mat-panel-title>
                  <!-- <mat-panel-description>
                  Currently I am {{ panelOpenState ? "open" : "closed" }}
                </mat-panel-description> -->
                </mat-expansion-panel-header>
                <mat-list>
                  <div *ngIf="pendingDiaryList.length == 0">Nothing here!</div>
                  <mat-list-item *ngFor="let diary of pendingDiaryList">
                    <div matListItemTitle>
                      {{ getDocumentName(diary.id) }}
                    </div>
                    <div matListItemLine>
                      {{ diary.id }}
                    </div>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </mat-list>
              </mat-expansion-panel>
              <mat-expansion-panel
                [expanded]="panelOpenState === 2"
                (opened)="setStep(2)"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon matListItemIcon>check_circle_outline</mat-icon>
                    <span>Approved</span>
                  </mat-panel-title>
                  <!-- <mat-panel-description>
                  Currently I am {{ panelOpenState ? "open" : "closed" }}
                </mat-panel-description> -->
                </mat-expansion-panel-header>
                <mat-list>
                  <div *ngIf="reviewedDiaryList.length == 0">Nothing here!</div>
                  <mat-list-item *ngFor="let diary of reviewedDiaryList">
                    <div matListItemTitle>
                      {{ getDocumentName(diary.id) }}
                    </div>
                    <div matListItemLine>
                      {{ diary.id }}
                    </div>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </mat-list>
              </mat-expansion-panel>
            </div>
          </mat-accordion>
        </mat-drawer>
        <mat-drawer-content>
          <mat-card class="example-card">
            <mat-card-header *ngIf="diaryInPreview != undefined">
              <div class="preview-header">
                <mat-card-title>{{ documentNameInPreview }}</mat-card-title>
                <mat-card-subtitle>{{ diaryIdInPreview }}</mat-card-subtitle>
              </div>
              <div class="paging-info-container">
                <span *ngIf="pageInPreview != -1"
                  >Document {{ pageInPreview + 1 }} of
                  {{ diaryInPreview.docDetails.length }}</span
                >
              </div>
              <div
                class="preview-navigation-container"
                *ngIf="
                  pageInPreview != -1 && diaryInPreview.docDetails.length > 1
                "
              >
                <mat-card-actions align="end">
                  <button
                    mat-icon-button
                    (click)="previousDocument()"
                    [disabled]="
                      pageInPreview == 0 && diaryInPreview.docDetails.length > 1
                    "
                  >
                    <mat-icon
                      aria-hidden="false"
                      aria-label="Example home icon"
                      fontIcon="arrow_back_ios"
                    ></mat-icon>
                  </button>

                  <button
                    mat-icon-button
                    (click)="nextDocument()"
                    [disabled]="
                      pageInPreview == diaryInPreview.docDetails.length - 1 &&
                      diaryInPreview.docDetails.length > 1
                    "
                  >
                    <mat-icon
                      aria-hidden="false"
                      aria-label="Example home icon"
                      fontIcon="arrow_forward_ios"
                      (click)="getDocuments(diaryInPreview)"
                    ></mat-icon>
                  </button>
                </mat-card-actions>
              </div>
            </mat-card-header>
            <mat-divider></mat-divider>
            <div class="preview-container">
              <!-- <div class="postit-container">
                <p>note or description</p>
              </div> -->

              <mat-spinner *ngIf="previewLoading"></mat-spinner>

              <img
                [src]="fileInPreview"
                *ngIf="fileTypeInPreview == 'image' && !previewLoading"
                height="100%"
                mat-card-image
                style="--transform-flip: {{ flip }}; --transform-rotate: {{
                  rotate + 'deg'
                }}; --transform-scale: {{ zoom }}"
                class="containerImg"
                src="https://adidriving.ie/wp-content/uploads/2021/03/New_Irish_Driving_Licence.png"
                alt="Preview file"
              />

              <!-- [ngClass]="{
                'rotate-left-360': previewRotate == -4,
                'rotate-left-270': previewRotate == -3,
                'rotate-left-180': previewRotate == -2,
                'rotate-left-90': previewRotate == -1,
                'rotate-0': previewRotate == 0,
                'rotate-right-90': previewRotate == 1,
                'rotate-right-180': previewRotate == 2,
                'rotate-right-270': previewRotate == 3,
                'rotate-right-360': previewRotate == 4
              }" -->

              <pdf-viewer
                style="--transform-flip: {{ flip }}"
                *ngIf="fileTypeInPreview == 'pdf' && !previewLoading"
                [src]="pdfInPreview"
                [render-text]="true"
                [original-size]="false"
                style="width: 100%; height: -webkit-fill-available"
                class="pdf-preview mat-elevation-z8"
                [render-text-mode]="2"
                [rotation]="pdfRotateAngle"
                [zoom]="zoom"
                [fit-to-page]="true"
                [zoom-scale]="'page-fit'"
                [show-all]="true"
                [show-borders]="true"
                [stick-to-page]="true"
                [page]="pageNumber"
                [autoresize]="true"
                (after-load-complete)="callback($event)"
              ></pdf-viewer>
              <!-- (page-rendered)="pageRendered($event)"
                (pages-initialized)="pageInitialized($event)"
                (text-layer-rendered)="textLayerRendered($event)"
                (error)="onError($event)"
                (on-progress)="onProgress($event)" -->
              <span *ngIf="diaryInPreview === undefined && !previewLoading"
                >No item selected</span
              >
              <div
                class="text-preview-container"
                *ngIf="fileTypeInPreview == 'text' && !previewLoading"
              >
                <span
                  *ngFor="let item of textListInPreview"
                  id="text-preview-item"
                >
                  {{ getItemName(item.key) | titlecase }} -
                  {{
                    item.key == "lastInsuredDate" ? item.value : item.value
                  }}</span
                >
              </div>
            </div>

            <mat-card-actions
              class="first-action-panel"
              align="end"
              *ngIf="diaryInPreview != undefined"
            >
              <div class="operation-buttons">
                <span class="horizontal-separator"></span>
                <button
                  matTooltip="zoom out"
                  matTooltipPosition="above"
                  mat-icon-button
                  color="primary"
                  aria-label="Example icon button with a home icon"
                  (click)="zoomOut()"
                  [disabled]="zoom <= 0.5"
                >
                  <mat-icon>zoom_out</mat-icon>
                </button>
                <button
                  matTooltip="restore zoom"
                  matTooltipPosition="above"
                  mat-icon-button
                  color="primary"
                  aria-label="Example icon button with a home icon"
                  (click)="restoreZoom()"
                  [disabled]="zoom == 1"
                >
                  <mat-icon>youtube_searched_for</mat-icon>
                </button>
                <button
                  matTooltip="zoom in"
                  matTooltipPosition="above"
                  mat-icon-button
                  color="primary"
                  aria-label="Example icon button with a home icon"
                  (click)="zoomIn()"
                >
                  <mat-icon>zoom_in</mat-icon>
                </button>
                <span class="horizontal-separator"></span>

                <button
                  *ngIf="pageInPreview != -1"
                  matTooltip="rotate to left"
                  matTooltipPosition="above"
                  mat-icon-button
                  color="primary"
                  (click)="rotateToLeft()"
                  [disabled]="previewRotate === -4"
                >
                  <mat-icon>rotate_left</mat-icon>
                </button>

                <button
                  *ngIf="pageInPreview != -1"
                  matTooltip="rotate to right"
                  matTooltipPosition="above"
                  mat-icon-button
                  color="primary"
                  (click)="rotateToRight()"
                  [disabled]="previewRotate === 4"
                >
                  <mat-icon>rotate_right</mat-icon>
                </button>
                <span class="horizontal-separator"></span>
                <button
                  matTooltip="mirror"
                  matTooltipPosition="above"
                  mat-icon-button
                  [color]="flip == 1 ? 'primary' : 'accent'"
                  aria-label="Example icon button with a menu icon"
                  (click)="mirror()"
                >
                  <mat-icon>flip</mat-icon>
                </button>
                <span class="horizontal-separator"></span>
                <!-- <button
                  matTooltip="previous page"
                  matTooltipPosition="above"
                  *ngIf="totalPage > 1"
                  mat-icon-button
                  color="primary"
                  aria-label="Example icon button with a menu icon"
                  (click)="previousPage()"
                  [disabled]="pageNumber == 1"
                >
                  <mat-icon>arrow_left</mat-icon>
                </button>
                <button
                  matTooltip="next page"
                  matTooltipPosition="above"
                  *ngIf="totalPage > 1"
                  mat-icon-button
                  color="primary"
                  aria-label="Example icon button with a heart icon"
                  (click)="nextPage()"
                  [disabled]="totalPage == pageNumber"
                >
                  <mat-icon>arrow_right</mat-icon>
                </button> -->
                <span class="horizontal-separator"></span>
              </div>
              <div class="validation-buttons">
                <button
                  mat-button
                  class="green-button"
                  (click)="acceptDocument()"
                >
                  Accept
                </button>
                <button
                  mat-button
                  class="red-button"
                  (click)="rejectDocument()"
                >
                  Reject
                </button>
              </div>
            </mat-card-actions>
          </mat-card>
        </mat-drawer-content>
      </mat-drawer-container>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-actions class="second-action-panel" align="end">
      <button
        (click)="downloadNBPack()"
        mat-flat-button
        color="accent"
        [disabled]="!isDownloadEnabled"
      >
        Download
      </button>
    </mat-card-actions>
  </mat-card>

  <app-overlapping-spinner
    *ngIf="isDownloadInProgress"
  ></app-overlapping-spinner>
</div>

<ng-template #messageTemplate>
  <div class="message-dialog-container">
    <div class="message-info">
      <h1 class="title">Oops... Something went wrong!</h1>
      <div class="message">
        Please try again in a few minutes or contact WebDev Team
      </div>
    </div>
    <div class="actions">
      <!-- <button mat-flat-button color="primary" (click)="cancel()">
        {{ cancelButtonText }}
      </button> -->
      <button mat-flat-button color="primary" mat-dialog-close>Ok</button>
    </div>
  </div>
</ng-template>
