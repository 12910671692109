import { Injectable, inject } from '@angular/core';
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ResolveFn,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Authorization } from '../models/authorization.model';
import { MsalService } from '@azure/msal-angular';
import { Account } from '../models/accountAAD.model';
import { RoleGuardService } from '../services/role-guard.service';

export const AuthorizationResolver: ResolveFn<Authorization> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<Authorization> => {
  return inject(RoleGuardService).Authorization();
};

// constructor(private authService: MsalService){}

//   resolve(): Observable<Authorization> {
//     let Authorizated: Authorization = new Authorization();
//     debugger
//     let account: Account = this.authService.instance.getAllAccounts()[0];
//     if (!account.idTokenClaims?.roles)
//       return of(Authorizated);
//     Authorizated.authorizedAdminAgent = account.idTokenClaims?.roles?.some((x: string) => x === 'AdminAgent');
//     Authorizated.authorizedAgent = account.idTokenClaims?.roles?.some((x: string) => x === 'Agent');
//     Authorizated.authorizedAdminIT = account.idTokenClaims?.roles?.some((x: string) => x === 'AdminIT');

//     // there is no AdminIT functionality yet
//     Authorizated.authorizedAdminAgent = Authorizated.authorizedAdminIT;

//     return of(Authorizated);
