import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { ValidateDiariesPageComponent } from '../validate-diaries-page/validate-diaries-page.component';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { LandingMainPageEvent } from 'src/app/models/events/landingMainPageEvent.model';
import { AgentModel } from 'src/app/models/agent.Model';
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event.service';
import { SelectionModel } from '@angular/cdk/collections';

import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SignalrService } from 'src/app/services/signalr.service';

import { ActivatedRoute } from '@angular/router';
import { forEach } from 'lodash';
import { UploadedDiariesModel } from 'src/app/models/uploadedDiaries.Model';
import { Authorization } from 'src/app/models/authorization.model';
import { agentAssignmentEvent } from 'src/app/models/events/agentAssignmentEvent.model';
import { SearchNoMatchUserComponent } from '../../components/search-no-match-user/search-no-match-user.component';

@Component({
  selector: 'app-onboarding-page',
  templateUrl: './onboarding-page.component.html',
  styleUrls: ['./onboarding-page.component.sass'],
})
export class OnboardingPageComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  numSelected: number = 0;
  allItems: UploadedDiariesModel[] = [];
  filteredItems: UploadedDiariesModel[] = [];
  userLoggedIn: any;
  assignedItems: UploadedDiariesModel[] = [];
  unassignedItems: UploadedDiariesModel[] = [];
  authorization: Authorization = new Authorization();

  constructor(
    private authService: AuthService,
    private onboardingService: OnboardingService,
    private eventService: EventService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private graph: AuthService,
    private signalRService: SignalrService,
    private route: ActivatedRoute
  ) {}

  displayedColumns: string[] = [
    'select',

    'policyCode',
    'diaryTotal',
    'dateSubmitted',
    'timeSubmitted',

    'file',
    'agentAssigned',
    'dateAssigned',
  ];
  selection = new SelectionModel<UploadedDiariesModel>(true, []);
  searchValue!: string;
  data = new MatTableDataSource<UploadedDiariesModel>();
  clickedRows = new Set<UploadedDiariesModel[]>();
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  agents: any[] = [];
  agentsSelected: any;
  agentsSelectedControl = new FormControl('');
  isAssignModeOn: boolean = false;
  isDisplayingOnlyUnassigned = false;
  assignedIsChecked = false;
  onboardingForm = this.fb.group({
    policyCode: [''],
  });
  filterDictionary = new Set<UploadedDiariesModel[]>();

  ngOnInit(): void {
    this.numSelected = 0;
    this.authorization = this.route.snapshot.data['authorization'];

    if (this.authorization.authorizedAdminAgent) this.isAssignModeOn = true;
    else this.isAssignModeOn = false;

    this.userLoggedIn = this.authService.getUserDetail();
    this.data.sort = this.sort;

    // this.listUploadedDiaries();
    this.graph.getGroupUserDetail().subscribe({
      next: (x) => {
        this.agents = x.value;
        // console.log(this.agents);
      },
      error: (e) => {
        console.error(e);
      },
    });
    this.signalRService.AllFeedObservable?.subscribe({
      next: (res: UploadedDiariesModel[]) => {
        // console.log(res);
        var user = this.authService.getUserDetail();
        if (this.authorization.authorizedAgent) {
          var a = <UploadedDiariesModel[]>res.filter((x) => {
            return x.agentEmail == user?.username;
          });
          // console.log(a);
        }

        this.listUploadedDiariesHub(res);
      },
    });
  }

  openDialog(): void {
    this.dialog.open(SearchNoMatchUserComponent, {
      height: '600px',
      width: '1200px',
    });
  }
  listUploadedDiariesHub(diaries: UploadedDiariesModel[]) {
    let newItems: UploadedDiariesModel[] = [];
    let removedItems: UploadedDiariesModel[] = [];
    let existentItems: UploadedDiariesModel[] = [];
    debugger;
    if (this.allItems.length == 0) {
      this.allItems.push(...diaries);
    } else {
      diaries.forEach((x) => {
        let auxItem = this.allItems.find((a) => a.policyCode === x.policyCode);

        if (auxItem != undefined && auxItem != null) {
          auxItem.agentEmail = x.agentEmail;
          auxItem.agentName = x.agentName;
          auxItem.dateUploaded = x.dateUploaded;
          auxItem.dateAssigned = x.dateAssigned;
          auxItem.diaryTotal = x.diaryTotal;

          existentItems.push(auxItem);
        } else newItems.push(x);
      });

      this.allItems.forEach((a) => {
        let auxItem = diaries.filter((x) => x.policyCode == a.policyCode);

        if (auxItem.length === 0) removedItems.push(a);
      });

      this.allItems.push(...newItems);
      this.allItems = this.allItems.filter((x) => !removedItems.includes(x));

      existentItems.forEach((existent) => {
        let item = this.allItems.filter(
          (x) => x.policyCode === existent.policyCode
        )[0];

        if (item != undefined) {
          item.agentEmail = existent.agentEmail;
          item.dateAssigned = existent.dateAssigned;
          item.diaryTotal = existent.diaryTotal;
        }
      });
    }

    this.configureView();
    // this.data.data = this.allItems;

    // this.data._renderChangesSubscription;

    this.isLoadingResults = false;
  }

  configureView() {
    if (
      this.authorization.authorizedAdminIT ||
      this.authorization.authorizedAdminAgent
    ) {
      if (this.isDisplayingOnlyUnassigned) {
        this.filteredItems = this.allItems.filter((x) => !x.agentEmail);
        this.data.data = this.filteredItems;
      } else {
        this.data.data = this.allItems;
      }
    } else {
      this.filteredItems = this.allItems.filter(
        (x) => x.agentEmail === this.userLoggedIn.username
      );

      this.data.data = this.filteredItems;
    }

    this.data._renderChangesSubscription;
  }

  getPolicyDetails(policyCode: string, agentEmail: string, dataUploaded: any) {
    debugger;
    //this.isOnboardingVisible = true;
    const dialogRef = this.dialog.open(ValidateDiariesPageComponent, {
      height: 'calc(100vh - 35px)',
      // position: { right: '0', top: '35px' },
      width: '90dvw',
      hasBackdrop: true,
      disableClose: true,
      data: {
        policyCode: policyCode,
        dateAssigned: dataUploaded,
        agentEmailAssigned: agentEmail,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      // console.log('return of the documents page');
      // console.log(res);

      if (res.isUploadedToRelay) this.listUploadedDiaries();
    });
  }

  listUploadedDiaries() {
    this.signalRService.AllFeedObservable;

    var user = this.authService.getUserDetail();
    let event = <LandingMainPageEvent>{};
    this.isLoadingResults = true;
    this.onboardingService.listUploadedDiaries().subscribe({
      next: (res) => {
        this.assignedItems = <UploadedDiariesModel[]>res.filter((x) => {
          x.agentEmail != '';
        });

        // this.unassignedItems = <UploadedDiariesModel[]>res.filter((x) => {
        //   x.agentAssigned == '';
        // });

        this.unassignedItems = res;
        //this.displayResultTable();

        // console.log(this.data.data);
        // event obj
        event = <LandingMainPageEvent>{
          sessionId: sessionStorage.getItem('sessionId'),
          agent: <AgentModel>{
            name: user?.name,
            email: user?.username,
          },
          policies: res,
          datetimeLogin: new Date().toDateString(),
          errorMessage: '',
        };

        console.warn(this.data);
        this.isLoadingResults = false;

        this.eventService.landingMaingPageEvent(event);
        this.data._updateChangeSubscription();
      },
      error: (error) => {
        event.errorMessage = error.message;
        this.isLoadingResults = false;
        this.eventService.landingMaingPageEvent(event);
      },
    });
  }

  displayResultTable() {
    this.data.data.push(...this.unassignedItems);

    // if (!this.isDisplayingOnlyUnassigned) {
    //   this.data.data.concat(...this.assignedItems);
    // }
  }

  filterAssignedAgents() {
    // if(this.assignedIsChecked){}

    this.configureView();

    const dataFiltered = this.data.data.filter(function (item) {
      return item.agentEmail != undefined;
    });

    //  let a =this.data.map(i2 => {
    //   debugger;
    //     const selected =  i2.agentAssigned ? i2 : null;
    //     return selected ? {...selected }: i2 ;
    //   })
    //   console.log(a);
  }

  setAgentsSelected() {
    debugger;

    let selected = this.selection.selected;
    selected.map((x) => {
      x.agentEmail = this.agentsSelected.userPrincipalName;
      x.agentName = this.agentsSelected.displayName;
      x.dateAssigned = new Date();
    });

    if (this.isDisplayingOnlyUnassigned) {
      this.assignedItems.push(...selected);
      this.unassignedItems.push();
      this.data.data = this.removeItemsFromArray(
        this.data.data,
        this.assignedItems
      );
    } else {
    }

    selected.map((x) => {
      const selected = this.data.data.find(
        (i2) => i2.policyCode == x.policyCode
      );
      return selected ? { ...x, ...selected } : x;
    });
    this.selection.clear();
    debugger;
    if (selected.length > 0) {
      this.onboardingService.saveAssignedTask(selected).subscribe((res) => {
        // console.log(res);

        let policies = selected.map((x) => {
          return {
            policyCode: x.policyCode,
            dateUploaded: x.dateUploaded,
          };
        });

        let assignment = <agentAssignmentEvent>{
          sessionId: sessionStorage.getItem('sessionId'),
          policies: policies,
          leadEmail: this.userLoggedIn.username,
          agentAssignedEmail: selected.map((x) => x.agentEmail)[0],
          eventTime: new Date(),
        };

        debugger;
        this.eventService.assignTaskEvent(assignment);
      });
    }
  }

  filterUnassignedOnly() {
    this.isDisplayingOnlyUnassigned = !this.isDisplayingOnlyUnassigned;

    this.configureView();

    // if (this.isDisplayingOnlyUnassigned)
    //   this.data.data = this.unassignedItems.filter((x) => !x.agentEmail);
    // else this.data.data = this.unassignedItems;

    // this.data._updateChangeSubscription();
  }

  removeItemsFromArray(sourceArray: any[], items: any[]) {
    items.forEach((x) => {
      let index = sourceArray.indexOf(x);
      sourceArray.splice(index, 1);
    });
    return sourceArray;
  }
  searchByPolicyCode() {
    // let value =
    //   this.onboardingForm.controls?.policyCode.value?.trimStart().length ===
    //   undefined
    //     ? 0
    //     : this.onboardingForm.controls?.policyCode.value?.trimStart().length;

    let policyCode =
      this.onboardingForm.controls?.policyCode.value?.toUpperCase() ?? '';

    // if (policyCode.trim().length > 0) {
    //   this.isLoadingResults = true;
    //   this.onboardingService.getDiaryByPolicyCode(policyCode.trim()).subscribe(
    //     (res) => {
    //       this.data = res;
    //       console.warn(JSON.stringify(this.data));
    //       this.isLoadingResults = false;
    //     },
    //     (error) => {
    //       this.isLoadingResults = false;
    //     }
    //   );
    // } else {
    //   this.isLoadingResults = true;
    //   this.onboardingService.listUploadedDiaries().subscribe(
    //     (res) => {
    //       this.data.data = res;
    //       console.warn(JSON.stringify(this.data));
    //       this.isLoadingResults = false;
    //     },
    //     (error) => {
    //       this.isLoadingResults = false;
    //     }
    //   );
    // }

    if (policyCode) {
      this.data.data = this.allItems.filter((x) => x.policyCode === policyCode);
      this.data._renderChangesSubscription;
    } else {
      this.configureView();
    }
  }

  getSelectedAmount() {
    return this.numSelected;
  }

  selectedRow(a: any) {
    this.selection.toggle(a);
    this.clickedRows.clear();
    this.clickedRows.add(this.selection.selected);
    // console.log(this.clickedRows);
    // console.log(this.selection);
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    // setTimeout(() => {
    this.numSelected = this.selection.selected.length;
    // }, 0);

    const numRows = this.data.data.length;
    return this.numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.numSelected = 0;
      this.selection.clear();
      return;
    }

    this.selection.select(...this.data.data);
  }
}
